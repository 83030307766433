import React, { useState, useEffect } from "react";
import {
    Grid,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    useMediaQuery,
} from "@mui/material";
import { ImportExport } from '@mui/icons-material';

//exportTransaction
import { mkConfig, generateCsv, download } from "export-to-csv";

//calendar
import dayjs from "dayjs";

//theme
import { SearchButton } from '../../Theme.jsx'

//icons
import { SearchTextIcon } from '../../untils/icons.js'

//services
import { POST, GET, TRANSACTIONSDEPOSIT, MERCHANT } from "../../service/service.js"


function ReportDeposit() {
    dayjs.locale("th");

    const isMobile = useMediaQuery('(max-width:599px)');

    const [text, setText] = useState('')
    // console.log('text:', text)
    const [exportTransaction, setExportTransaction] = useState([]);
    // console.log('exportTransaction:', exportTransaction)
    const [transactions, setTransactions] = useState([]);
    // console.log('transactions:', transactions)
    const [broker, setBroker] = useState("All");
    // console.log('broker:', broker)
    const [brokerList, setBrokerList] = useState([]);
    // console.log('brokerList:', brokerList)
    const [status, setStatus] = useState("All")
    // console.log('status:', status)
    const [startDate, setStartDate] = useState(dayjs().startOf("month").format("YYYY-MM-DD"));
    // console.log('startDate:', startDate)
    const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
    // console.log('endDate:', endDate)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const filename = `${startDate}_${endDate}_Deposit_transactions_merchant_${broker}_status_${status}`;
    const csvConfig = mkConfig({ useKeysAsHeaders: true, filename });

    useEffect(() => {
        getTransactionMerchant({ merchant_name: broker, startDate, endDate, search: text, })
        getBroker();
    }, []);

    const getTransactionMerchant = async ({ merchant_name: broker, startDate, endDate, search: text, }) => {
        try {
            const res = await POST(TRANSACTIONSDEPOSIT, {
                search: text,
                merchant_name: broker,
                status: status,
                startDate: startDate,
                endDate: endDate
            })
            if (res.success) {
                setTransactions(res.result)
                setExportTransaction(res.result)
            } else {
                alert(res.message)
            }
        } catch (error) {
            alert("error", error)
        }
    }

    const getBroker = async () => {
        try {
            const res = await GET(MERCHANT);
            if (res.success) {
                setBrokerList(res.result);
            } else {
                alert(res.message);
            }
        } catch (error) {
            alert("Error fetching brokers");
        }
    };

    const handleSearch = () => {
        getTransactionMerchant({ merchant_name: broker, startDate, endDate, search: text, })
        setPage(0);
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return 'yellow';
            case 'successful':
                return 'green';
            case 'failed':
                return 'red';
            default:
                return 'black';
        }
    };

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(+e.target.value);
        setPage(0);
    };

    const exTransaction = async () => {
        let data = exportTransaction.map(e => {
            return {
                transactionsid: e.id,
                merchantname: e.merchant.name,
                amount: e.amount,
                balance_before: e.balanceBefore,
                balance_after: e.balanceAfter,
                amount_with_fee: e.amountWithFee,
                clientTransactionId: e.clientTransactionId,
                status: e.status,
                date_created: e.dateCreated,
                date_updated: e.dateUpdated
            };
        });

        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv);
    };

    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ marginTop: "20px" }}
        >
            <Grid item xs={12} md={8}>
                <Typography variant="h6" color="#2996D1" gutterBottom>
                    ค้นหา
                </Typography>
                <Grid container spacing={2} marginBottom={2} alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <TextField
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                variant="outlined"
                                placeholder='ค้นหา'
                                required
                                fullWidth
                            >
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <FormControl fullWidth>
                            <InputLabel>ร้านค้า</InputLabel>
                            <Select
                                value={broker}
                                onChange={(e) => setBroker(e.target.value)}
                                label='Broker'
                            >
                                <MenuItem value={"All"}>{"All"}</MenuItem>
                                {brokerList.map((item) => (
                                    <MenuItem key={item.name} value={item.name}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                label='Status'
                            >
                                <MenuItem value={'All'}>ทั้งหมด</MenuItem>
                                <MenuItem value={'pending'}>Pending</MenuItem>
                                <MenuItem value={'successful'}>Successful</MenuItem>
                                <MenuItem value={'failed'}>Failed</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            fullWidth
                            required
                            label="วันที่เริ่มต้น"
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            fullWidth
                            required
                            label="วันที่สิ้นสุด"
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} sx={{ mt: 1 }}>
                        <SearchButton width="auto" variant="outlined" onClick={handleSearch} startIcon={<SearchTextIcon />}>ค้นหา</SearchButton>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} sx={{ mt: 1 }}>
                        <SearchButton width="auto" variant="outlined" onClick={exTransaction} startIcon={<ImportExport />}>Export</SearchButton>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    {isMobile ? (
                        <Table>
                            <TableBody>
                                {transactions.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            No records to display
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    transactions
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((transaction) => (
                                            <TableRow key={transaction.id}>
                                                <TableCell>
                                                    <div className="transaction-card">
                                                        <p><strong>Client Transaction ID:</strong> {transaction.clientTransactionId}</p>
                                                        <p><strong>System Transaction ID:</strong> {transaction.systemTransactionId}</p>
                                                        <p><strong>Merchant Name:</strong> {transaction.merchant.name}</p>
                                                        <p><strong>Status:</strong> <span style={{ color: getStatusColor(transaction.status) }}>{transaction.status}</span></p>
                                                        <p><strong>Date Created:</strong> {dayjs(transaction.dateCreated).format("DD-MM-YYYY HH:mm:ss")}</p>
                                                        <p><strong>Date Updated:</strong> {dayjs(transaction.dateUpdated).format("DD-MM-YYYY HH:mm:ss")}</p>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                )}
                            </TableBody>
                        </Table>
                    ) : (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Client Transaction Id</TableCell>
                                    <TableCell>System Transaction Id</TableCell>
                                    <TableCell>Merchant Name</TableCell>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Balance Before</TableCell>
                                    <TableCell>Balance After</TableCell>
                                    <TableCell>Amount With Fee</TableCell>
                                    <TableCell>Date Created</TableCell>
                                    <TableCell>Date Updated</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transactions.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={18} align="center">
                                            No records to display
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    transactions
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((transaction) => (
                                            <TableRow key={transaction.id}>
                                                <TableCell style={{ color: getStatusColor(transaction.status) }}>
                                                    {transaction.status}
                                                </TableCell>
                                                <TableCell>{transaction.clientTransactionId}</TableCell>
                                                <TableCell>{transaction.systemTransactionId}</TableCell>
                                                <TableCell>{transaction.merchant.name}</TableCell>
                                                <TableCell>{transaction.id}</TableCell>
                                                <TableCell>{transaction.amount}</TableCell>
                                                <TableCell>{transaction.balanceBefore}</TableCell>
                                                <TableCell>{transaction.balanceAfter}</TableCell>
                                                <TableCell>{transaction.amountWithFee}</TableCell>
                                                <TableCell>{dayjs(transaction.dateCreated).format("DD-MM-YYYY HH:mm:ss")}</TableCell>
                                                <TableCell>{dayjs(transaction.dateUpdated).format("DD-MM-YYYY HH:mm:ss")}</TableCell>
                                            </TableRow>
                                        ))
                                )}
                            </TableBody>
                        </Table>
                    )}

                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        labelRowsPerPage="Rows per page"
                        count={transactions.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Grid>
        </Grid>
    );
};



export default ReportDeposit;